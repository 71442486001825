<section style="font-size: 13px; border-radius: 10px;" class="bg-light p-3">

  <p class="text-center"><b>¿Necesitas Ayuda?</b></p>

  <p>Comunícate con nosotros a través de nuestro FONO EMERGENCIA las 24 horas del día:</p>

  <p class="text-center">{{empresa.telefonos}}</p>

  <p class="text-justify">
    Para inquietudes respecto a su importe de facturación, consumo de agua potable y facturación, comuníquese a través
    de llamadas, WhatsApp y mensajes de textos a los siguientes números:
  </p>
  <div class="text-center">
    <p class="mb-1"><b>{{empresa.nombre}} </b></p>
    <p class="mb-1"> {{empresa.telefonos}}</p>
    <!--<p class="mb-1"><b>Nuevo Chimbote:</b></p>
    <p class="mb-1">970710788 - 977642606</p>
    <p class="mb-1"><b>Casma:</b></p>
    <p class="mb-1"> 943678247</p>
    <p class="mb-1"><b>Huarmey:</b></p>
    <p class="mb-0"> 970877641</p>-->
  </div>


</section>

<section class="text-center bg-light my-2 py-4" style="border-radius: 10px;">

  <iframe
    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FEmapiscoSA%2F&tabs=timeline%2Cmessages%2Cevents&width=300&height=500&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId"
    height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
    allowfullscreen="true"
    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
</section>


<style>
  .bg-seda{
    background: linear-gradient(to bottom right, #1F1D40, #7CC9D2);
    color: #fff;
  }
</style>
