<a *ngIf="tipoUrl==1" href="{{subMenu.url}}" target="_blank">
  <i [ngClass]="subMenu.icono"></i>
  <span>{{subMenu.nombre}}</span>
</a>

<a *ngIf="tipoUrl==2 && subMenu.idPagina!=null" href="#" [routerLink]="[subMenu.url, subMenu.idPagina]">
  <i [ngClass]="subMenu.icono"></i>
  <span>{{subMenu.nombre}}</span>
</a>

<a *ngIf="tipoUrl==2 && subMenu.idPagina==null" href="#" [routerLink]="[subMenu.url]">
  <i [ngClass]="subMenu.icono"></i>
  <span>{{subMenu.nombre}}</span>
</a>
