import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRouting} from './app.routing';
import {SharedModule} from './shared/shared.module';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {registerLocaleData} from '@angular/common';
import localEsPe from '@angular/common/locales/es-PE';

registerLocaleData(localEsPe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRouting,
    SharedModule,
    BrowserAnimationsModule,
    NgbModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'es-PE'}],
  bootstrap: [AppComponent]
})
export class AppModule {
}
