import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Enlace} from '../models/enlace';
import {Empresa} from '../models/empresa';
import {Menu} from '../models/menu';
import {Submenu} from '../models/submenu';
import {Institucional} from '../models/institucional';
import {URI} from './Global';
import {Respuesta} from '../models/Respuesta';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private http: HttpClient
  ) {
  }

  private _colorBackground: string = 'blue-linear-background-header';
  private _colorServicios: string = 'our-services-mega-menu-header-items';

  get colorServicios(): string {
    return this._colorServicios;
  }

  get colorBackground(): string {
    return this._colorBackground;
  }

  public modificarColores() {
    this._colorBackground = this._colorBackground.match('blue-linear-background-header') ? 'seda-linear-background-header' : 'blue-linear-background-header';
    this._colorServicios = this._colorServicios.match('our-services-mega-menu-header-items') ? 'our-services-mega-menu-header-items' : 'our-services-mega-menu-header-items';
  }

  public obtenerEnlaces(): Observable<Enlace[]> {
    return this.http.get<Enlace[]>(`${URI}/enlace/obtenerEnlaces.php`);
  }

  public obtenerEmpresas(): Observable<Empresa> {
    return this.http.get<Empresa>(`${URI}/empresa/obtenerEmpresa.php`);
  }

  public obtenerMenus(): Observable<any> {
    return this.http.get<any>(`${URI}/menu/obtenerMenus.php`);
  }

  public obtenerSubMenus(idPadre: number): Observable<Respuesta> {
    return this.http.get<Respuesta>(`${URI}/submenu/obtenerSubMenusPadre.php?idPadre=${idPadre}`);
  }

  public obtenerSubMenusLimite(idPadre: number, inf: number, sup: number): Observable<Submenu[]> {
    return this.http.get<Submenu[]>(`${URI}/submenu/obtenerSubMenusLimite.php?idPadre=${idPadre}&inf=${inf}&sup=${sup}`);
  }

  public obtenerInstitucional(): Observable<Institucional[]> {
    return this.http.get<Institucional[]>(`${URI}/institucional/obtenerInstitucional.php`);
  }
}
