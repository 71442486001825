import {Component, OnInit} from '@angular/core';
import {Empresa} from '../../models/empresa';
import {SharedService} from '../shared.service';
import {Institucional} from '../../models/institucional';
import {URI_LOGOS} from '../Global';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  URI_LOGOS: string = URI_LOGOS;

  empresa: Empresa;
  institucional: Institucional[] = [];
  otros: Institucional[] = [];

  constructor(
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {

    this.sharedService.obtenerEmpresas().subscribe((empresa: Empresa) => {
      this.empresa = empresa;
    });

    this.sharedService.obtenerInstitucional().subscribe((institucionals: Institucional[]) => {
      institucionals.map((institucional: Institucional) => {

        let institucionalNuevo = new Institucional();
        institucionalNuevo.id = institucional.id;
        institucionalNuevo.nombre = institucional.nombre;
        institucionalNuevo.url = institucional.url;
        institucionalNuevo.id_pagina = institucional.id_pagina;

        if (institucional.tipo === 'institucional') {
          this.institucional.push(institucionalNuevo);
        }
        if (institucional.tipo === 'otros') {
          this.otros.push(institucionalNuevo);
        }
      });

    }, error => {
      console.log(error);
    });
  }

  subirBarra(url: string) {

    if (url.match('http')) {
      let urlActual = window.location.href;
      window.location.href = url;
      window.location.href = urlActual;
    }


    $('body, html').animate({
      scrollTop: '0px'
    }, 1000);
  }
}
