// export const URI = 'http://localhost/apisedaweb/requests';
export const URI_LOCALHOST = 'https://emapisco.com/apisedaweb/requests';
//export const URI = 'https://www.sedachimbote.com.pe/apisedaweb/requests';

export const URI_PAGE = 'https://www.sedachimbote.com.pe/demo';

// export const URI_LOGOS = 'http://localhost/apisedaweb/assets/images/empresa/';
//export const URI_LOGOS = 'https://www.sedachimbote.com.pe/apisedaweb/assets/images/empresa/';

// export const URI_IMAGEN = 'http://localhost/apisedaweb/assets/images';
//export const URI_IMAGEN = 'https://www.sedachimbote.com.pe/apisedaweb/assets/images';


export const URI = 'https://emapisco.com/apisedaweb/requests';
export const URI_IMAGEN = 'https://emapisco.com/apisedaweb/assets/images';
export const URI_LOGOS = 'https://emapisco.com/apisedaweb/assets/images/empresa/';
