import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PreloaderComponent} from './preloader/preloader.component';
import {HeaderComponent} from './header/header.component';
import {HeadLineComponent} from './head-line/head-line.component';
import {MenuComponent} from './menu/menu.component';
import {MenuSelectorComponent} from './menu/menu-selector/menu-selector.component';
import {PrincipalChComponent} from './menu/principal-ch/principal-ch.component';
import {PrincipalShComponent} from './menu/principal-sh/principal-sh.component';
import {SecundarioComponent} from './menu/secundario/secundario.component';
import {SearchComponent} from './search/search.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './footer/footer.component';
import {EnlaceComponent} from './enlaces/enlace/enlace.component';
import {EnlacesComponent} from './enlaces/enlaces.component';
import {MenuCortoComponent} from './menu/menu-corto/menu-corto.component';
import {BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import {VacioComponent} from './vacio/vacio.component';
import {CargandoComponent} from './cargando/cargando.component';
import {BarraInformacionComponent} from './barra-informacion/barra-informacion.component';
import {ComponenteVacioComponent} from './notificaciones/componente-vacio/componente-vacio.component';


@NgModule({
  declarations: [
    PreloaderComponent,
    HeaderComponent,
    HeadLineComponent,
    MenuComponent,
    MenuSelectorComponent,
    PrincipalChComponent,
    PrincipalShComponent,
    SecundarioComponent,
    SearchComponent,
    FooterComponent,
    EnlaceComponent,
    EnlacesComponent,
    MenuCortoComponent,
    BreadcrumbComponent,
    VacioComponent,
    CargandoComponent,
    BarraInformacionComponent,
    ComponenteVacioComponent
  ],
  exports: [
    PreloaderComponent,
    HeaderComponent,
    HeadLineComponent,
    MenuComponent,
    FooterComponent,
    EnlacesComponent,
    MenuCortoComponent,
    BreadcrumbComponent,
    VacioComponent,
    BarraInformacionComponent,
    ComponenteVacioComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class SharedModule {
}
