<section *ngIf="mostrarEnlaces" class="our-sevices-section mb-0" style="height: 230px;">

  <div class="tittle-simple-one">
    <h5 class="font-size-33" style="color: #1F1D40">ENLACES DE INTERES</h5>
  </div>

  <div class="container mb-2">

    <div id="oursevices" class="carousel carousel-enlaces py-3" style="background: #7CC9D2">

      <app-enlace *ngFor="let enlace of enlaces" class="carousel-cell mx-1" [enlace]="enlace"></app-enlace>

    </div>
  </div>
</section>
