<div class="header-heeadline">
  <div class="text-center mt-3">

    <!--<h5><span class="blok-on-phon">Servicio de agua potable y alcantarillado en </span> <span class="typed"></span></h5>-->

    <p class="text-lg-center text-justify px-4">Porque nos preocupamos por ustedes, {{empresa.nombre}} pone a su disposición
      una plataforma virtual renovada con novedades para su seguridad y tranqulidad</p>

  </div>
</div>
