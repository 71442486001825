<div class="card border-info">
  <div class="card-header bg-info">
    <h4 class="mb-0 text-white text-center"><i class="fas fa-info-circle"></i> No se encontraron datos para mostrar <i
      class="fas fa-info-circle"></i></h4></div>
  <div class="card-body text-center">
    <h3 class="card-title text-center">Vista vacía</h3>
    <p class="card-text text-justify">Lo sentimos, en este momento no hay ningun dato disponible para visualizar en pantalla, pronto
      estaremos actualizando esta sección. Disculpe las molestias</p>
    <a href="javascript:void(0)" class="btn btn-info" routerLink="/">Ir a casa</a>
  </div>
</div>
