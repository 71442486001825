<div *ngIf="card" class="card p-1 bg-light">
  <div class="card-body text-center text-dark">
    <h4 class="text-inverse">
      <i class="fas fa-exclamation-circle"></i>
      {{card.titulo}}
      <i class="fas fa-exclamation-circle"></i>
    </h4>
    <br>
    <i class="fas fa-exclamation-triangle fa-4x mt-1 mb-3"></i>
    <br>
    <br>
    <p class="font-16">
      {{card.texto}}
    </p>
  </div>
</div>
