<li class="nav-item mega-dropdown" [id]="'menu' + menu.id" (mouseover)="mostrar(menu.id)">
  <a class="nav-link dropdown text-white" href="#" id="megadrop-services2" data-toggle="dropdown" aria-haspopup="true"
     aria-expanded="false">{{menu.nombre}} <span class="caret"></span></a>

  <div class="dropdown-menu mega-dropdown-menu" [id]="'submenu'+menu.id" (mouseleave)="noMostrar(menu.id)"
       style="background-color: transparent !important; border: solid transparent"
       aria-labelledby="megadrop-services">
    <div class="our-services-mega-menu-header" style="margin-top: -16px;">
      <div class="row justify-content-center our-services-mega-menu-header-items bg-white" style="border-radius: 20px">

        <app-secundario class="{{menu.columnas}}" *ngFor="let submenu of submenus;" [subMenu]="submenu"
                        style="border-radius: 25px">
        </app-secundario>

      </div>
    </div>
  </div>
</li>
