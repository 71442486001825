<section *ngIf="empresa && institucional.length>0" class="footer-coodiv-thm">
  <div class="container">
    <div class="row justify-content-center">

      <div class="col-md-5">
        <a class="footer-brand" href="#">
          <img [src]="URI_LOGOS + empresa.logoOscuro" style="width: 250px; height: 70px" alt="">
        </a>

        <a class="footer-contact-a-hm"><i class="fas fa-life-ring"></i> {{empresa.telefonos}}</a>
        <a class="footer-contact-a-hm"><i class="fas fa-microphone"></i> {{empresa.correo}}</a>
        <a class="footer-contact-a-hm"><i class="fas fa-map-marker-alt"></i> {{empresa.direccion}}</a>

      </div>

      <div class="col-md-4 quiq-links-footer-mb-st">
        <h5 class="footer-title-simple">Institucional</h5>
        <ul class="main-menu-footer-mn">
          <li *ngFor="let institucion of institucional">
            <a href="#" [routerLink]="[institucion.url, institucion.id]"
               (click)="subirBarra(institucion.url)">{{institucion.nombre}}
            </a>
          </li>
        </ul>
      </div>

      <div class="col-md-3 quiq-links-footer-mb-st">
        <h5 class="footer-title-simple">Sobre Nosotros</h5>
        <ul class="main-menu-footer-mn">
          <li *ngFor="let otro of otros">
            <a href="#" [routerLink]="[otro.url, otro.id]" (click)="subirBarra(otro.url)">{{otro.nombre}}
            </a>
          </li>
        </ul>
      </div>

    </div>

    <div class="row justify-content-center">
      <div class="col-md-6 text-center stay-in-tch-footer-mb-st">

        <div class="footer-social-links">
          <a class="facebookcc" href="#"><i class="fab fa-facebook-f"></i></a>
          <a class="twittercc" href="#"><i class="fab fa-twitter"></i></a>
          <a class="googlecc" href="#"><i class="fab fa-google-plus-g"></i></a>
          <a class="dribbblecc" href="#"><i class="fab fa-dribbble"></i></a>
        </div>
        <p class="copyright-footer-p">©2023 {{empresa.nombre}} Hecho con el amor a nuestros clientes.</p>

      </div>
    </div>

  </div>

</section>
