import {Component, OnInit} from '@angular/core';
import {Enlace} from '../../models/enlace';
import {SharedService} from '../shared.service';

declare var $: any;

@Component({
  selector: 'app-enlaces',
  templateUrl: './enlaces.component.html',
  styles: []
})
export class EnlacesComponent implements OnInit {
  enlaces: Enlace[] = [];
  mostrarEnlaces: boolean = false;

  constructor(
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {

    this.sharedService.obtenerEnlaces().subscribe((enlaces: Enlace[]) => {
      enlaces.map((enlace: Enlace) => {

        let enlaceNuevo = new Enlace();
        enlaceNuevo.id = enlace.id;
        enlaceNuevo.nombre = enlace.nombre;
        enlaceNuevo.imagen = enlace.imagen;
        enlaceNuevo.url = enlace.url;

        this.enlaces.push(enlaceNuevo);
      });
    }, error => {
      console.log(error);
      this.mostrarEnlaces = false;
    }, () => {

      function cargarCaroulse(): void {
        $('.carousel-enlaces').flickity({
          cellAlign: 'left',
          contain: true,
          autoplay: true,
          prevNextButton: true,
          freeScroll: true,
          wrapAround: true,
          autoPlay: 1000
        });
      }

      setTimeout(cargarCaroulse, 500);
      this.mostrarEnlaces = true;
    });

  }
}
