import {Component, Input, OnInit} from '@angular/core';
import {Menu} from '../../../models/menu';

@Component({
  selector: 'app-menu-selector',
  templateUrl: './menu-selector.component.html',
  styles: []
})
export class MenuSelectorComponent implements OnInit {

  @Input() menu: Menu;
  @Input() color: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
