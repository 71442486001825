import {Component, Input, OnInit} from '@angular/core';
import {Menu} from '../../../models/menu';

@Component({
  selector: 'app-principal-sh',
  templateUrl: './principal-sh.component.html',
  styles: []
})
export class PrincipalShComponent implements OnInit {
  @Input() menu: Menu;

  constructor() {
  }

  ngOnInit(): void {
  }

}
