<div class="search-header-block">

  <form id="pitursrach-header" name="formsearch" method="get" action="#">
    <input name="s" id="search" type="text" class="text" value=""
           placeholder="START TYPING AND PRESS ENTER TO SEARCH">
    <button type="submit" class="submit"><span class="fa fa-search"></span></button>
    <a class="closesrch-her-block np-dsp-block">
      <span class="first-stright"></span>
      <span class="second-stright"></span>
    </a>
  </form>

</div>
