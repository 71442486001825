import {Component, Input, OnInit} from '@angular/core';
import {Submenu} from '../../../models/submenu';

@Component({
  selector: 'app-secundario',
  templateUrl: './secundario.component.html',
  styles: []
})
export class SecundarioComponent implements OnInit {
  @Input() subMenu: Submenu;
  tipoUrl: number;

  constructor() {
  }

  ngOnInit(): void {
    this.tipoUrl = this.subMenu.url.toString().startsWith('http') ? 1 : 2;
  }

}
