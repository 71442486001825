import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { Empresa } from 'src/app/models/empresa';

@Component({
  selector: 'app-barra-informacion',
  templateUrl: './barra-informacion.component.html',
  styleUrls: ['./barra-informacion.component.css']
})
export class BarraInformacionComponent implements OnInit {
  empresa: Empresa = new Empresa
  constructor(
    private sharedService: SharedService
  ) {this.sharedService.obtenerEmpresas().subscribe((empresa: Empresa) => {
    this.empresa = empresa
  });
}

  ngOnInit(): void {
  }

}
