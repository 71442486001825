import {Component, Input, OnInit} from '@angular/core';
import {Card} from '../../models/card';

@Component({
  selector: 'app-vacio',
  templateUrl: './vacio.component.html',
  styles: []
})
export class VacioComponent implements OnInit {

  @Input() card: Card;

  constructor() {
  }

  ngOnInit(): void {
  }

}
