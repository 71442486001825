<div *ngIf="empresa" class="header-top-menu my-0" [ngClass]="colorBack" style="background-color: #00bed1;">
  <div class="container">
    <ul>
      <li><a><i class="fas fa-at"></i> {{empresa.correo}}</a></li>
      <li><a><i class="fas fa-phone"></i> {{empresa.telefonos}}</a></li>
    </ul>

    <div class="ml-auto fl-right phone-no-display">
      <ul>
        <li><a href="#"><i class="fa fa-home" [routerLink]="['/pages/home']"></i> Inicio </a></li>
        <li><a href="" target="_blank"><i class="fa fa-mail-bulk"></i> Correo </a>
        </li><!--https://correo.sedachimbote.com.pe/-->
        <li><a [routerLink]="['/pages/servicios/contactanos']"><i class="fa fa-file-contract"></i> Contactanos </a></li>
        <li class="custom-control custom-switch" (change)="cambio()">
          <input type="checkbox" class="custom-control-input " id="customSwitch2">
          <label class="custom-control-label bg-success" for="customSwitch2"></label>
        </li>
      </ul>
    </div>

  </div>
</div>
