import {Component, OnInit} from '@angular/core';
import {SharedService} from '../../shared.service';
import {Menu} from '../../../models/menu';
import {Empresa} from '../../../models/empresa';
import {URI_LOGOS} from '../../Global';

declare var $: any;

@Component({
  selector: 'app-menu-corto',
  templateUrl: './menu-corto.component.html',
  styles: []
})
export class MenuCortoComponent implements OnInit {
  menus: Menu[] = [];
  empresa: Empresa;

  URI_LOGOS: string = URI_LOGOS;

  constructor(
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {
    this.sharedService.obtenerMenus().subscribe((respuesta) => {
      this.menus = JSON.parse(respuesta.json);
    }, error => {
      console.log(error);
    });

    $(window).scroll(function() {
      const scroll = $(window).scrollTop();
      if (scroll >= 500) {
        $('.top-header-nav-home').addClass('top-header-fixed');
      } else {
        $('.top-header-nav-home').removeClass('top-header-fixed');
      }

      if (scroll <= 600) {
        $('.top-header-fixed').addClass('top-header-fixed-btom0');
      } else {
        $('.top-header-fixed').removeClass('top-header-fixed-btom0');
      }
    });

    this.obtenerEmpresa();
  }

  obtenerEmpresa() {
    this.sharedService.obtenerEmpresas().subscribe((empresa: Empresa) => {
      this.empresa = empresa;
    }, error => {
      console.log(error);
    });
  }

  mostrar: boolean = false;

  mostrarOcultarMenu() {
    if (!this.mostrar) {
      $('body').addClass('offcanvas-stop-scrolling');
      $('#offcanvas-menu-home').addClass('in');
    } else {
      $('body').removeClass('offcanvas-stop-scrolling');
      $('#offcanvas-menu-home').removeClass('in');
    }
    this.mostrar = !this.mostrar;
  }

}
