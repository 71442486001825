import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Empresa} from '../../models/empresa';
import {SharedService} from '../shared.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  empresa: Empresa;
  colorBack: string;
  colorServicio: string;

  @Output() obtenerColor = new EventEmitter;

  constructor(
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {
    this.colorBack = this.sharedService.colorBackground;
    this.colorServicio = this.sharedService.colorServicios;

    this.sharedService.obtenerEmpresas().subscribe((empresa: Empresa) => {
      this.empresa = empresa;
    }, error => {
      console.log(error);
    });
  }

  cambio() {
    this.sharedService.modificarColores();
    this.obtenerColor.emit(this.sharedService.colorBackground);
  }
}
