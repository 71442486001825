import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styles: []
})
export class PreloaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
    $('.preloader').delay(500).fadeOut('slow');
  }

}
