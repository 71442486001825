export class Institucional {
  id: number;
  id_pagina: number;
  nombre: string;
  url: string;
  tipo: string;
  creado: Date;
  modificado: Date;
  mensaje: string;
}
