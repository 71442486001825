import {Component, Input, OnInit} from '@angular/core';
import {Enlace} from '../../../models/enlace';
import {URI_IMAGEN} from '../../Global';

@Component({
  selector: 'app-enlace',
  templateUrl: './enlace.component.html',
  styles: []
})
export class EnlaceComponent implements OnInit {
  URI_IMG_ENLACES = URI_IMAGEN + '/enlaces/';
  @Input() enlace: Enlace;

  constructor() {
  }

  ngOnInit(): void {
  }

}
