export class Enlace {
  id: number;
  nombre: string;
  imagen: string;
  url: string;
  estado: number;
  creado: Date;
  modificado: Date;
  mensaje: string;
}
