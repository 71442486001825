import {Component, Input, OnInit} from '@angular/core';
import {Menu} from '../../../models/menu';
import {Submenu} from '../../../models/submenu';
import {SharedService} from '../../shared.service';

@Component({
  selector: 'app-principal-ch',
  templateUrl: './principal-ch.component.html',
  styles: []
})
export class PrincipalChComponent implements OnInit {
  @Input() menu: Menu;
  @Input() color: string;
  submenus: Submenu[] = [];

  constructor(
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {
    this.sharedService.obtenerSubMenusLimite(this.menu.id, 0, this.menu.cantidad).subscribe((submenus: Submenu[]) => {
      if (submenus.length > 0) {
        submenus.forEach((submenu: Submenu) => {
          let nuevoSubmenu = new Submenu();

          nuevoSubmenu.id = submenu.id;
          nuevoSubmenu.nombre = submenu.nombre;
          nuevoSubmenu.icono = submenu.icono;
          nuevoSubmenu.url = submenu.url;
          nuevoSubmenu.idPadre = submenu.idPadre;
          nuevoSubmenu.idPagina = submenu.idPagina;
          nuevoSubmenu.descripcion = submenu.descripcion;

          this.submenus.push(nuevoSubmenu);
        });
      } else {
        console.log(submenus['mensaje']);
      }


    }, error => {
      console.log(error);
    });
  }

  mostrar(number: number) {
    let menu = document.getElementById('menu' + number);
    let submenu = document.getElementById('submenu' + number);
    let todos = document.getElementsByClassName('mega-dropdown-menu');

    for (let i = 0; i < todos.length; i++) {
      if (todos[i].classList.contains('show')) {
        todos[i].classList.remove('show');
      }
    }

    if (!menu.classList.contains('show')) {
      menu.classList.add('show');
    }

    if (!submenu.classList.contains('show')) {
      submenu.classList.add('show');
    }
  }

  noMostrar(number: number) {
    let menu = document.getElementById('menu' + number);
    let submenu = document.getElementById('submenu' + number);
    let todos = document.getElementsByClassName('mega-dropdown-menu');

    if (menu.classList.contains('show')) {
      menu.classList.remove('show');
    }

    if (submenu.classList.contains('show')) {
      submenu.classList.remove('show');
    }

    for (let i = 0; i < todos.length; i++) {
      if (todos[i].classList.contains('show')) {
        todos[i].classList.remove('show');
      }
    }
  }
}
