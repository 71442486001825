import {Component, OnInit} from '@angular/core';
import { Empresa } from 'src/app/models/empresa';
import Typed from 'typed.js';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-head-line',
  templateUrl: './head-line.component.html',
  styles: []
})
export class HeadLineComponent implements OnInit {
  empresa: Empresa;
  zonas: string[] = ['', ' Santa.', ' Casma.', ' Huarmey.'];

  constructor( private sharedService: SharedService) {

    this.sharedService.obtenerEmpresas().subscribe((empresa: Empresa) => {
      this.empresa = empresa;
    });
  }

  ngOnInit(): void {
    // this.typedZonas();
  }

  typedZonas() {
    let options = {
      strings: this.zonas,
      typeSpeed: 60,
      loop: true
    };
    new Typed('.typed', options);
  }

}
