<div *ngIf="final" class="container the_breadcrumb_conatiner_page">
  <div class="the_breadcrumb">
    <div class="breadcrumbs">
      <i class="fas fa-home"></i>
      <span *ngFor="let bread of breads">
        <a href="#" [routerLink]="bread.url">{{bread.bread}}</a>/
      </span>
      {{final}}
    </div>
  </div>
</div>
