export class Submenu {
  id: number;
  idPadre: number;
  idPagina: number;
  nombre: string;
  descripcion: string;
  icono: string;
  url: string;
  mostrar: boolean;
  estado: boolean;
  creado: Date;
  modificado: Date;
  mensaje: string;
}
