export class Empresa {
  id: number;
  nombre: string;
  telefonos: string;
  correo: string;
  direccion: string;
  facebook: string;
  twitter: string;
  google: string;
  instagram: string;
  logoClaro: string;
  logoOscuro: string;
  logoTexto: string;
  mascota: string;
  creado: Date;
  modificado: Date;
  mensaje: string;
}
