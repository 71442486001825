import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

}
